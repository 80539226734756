// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-serp-js": () => import("/Users/fkaroot/dev/betterhealth.com/hello-world/src/templates/serp.js" /* webpackChunkName: "component---src-templates-serp-js" */),
  "component---src-templates-toplist-template-js": () => import("/Users/fkaroot/dev/betterhealth.com/hello-world/src/templates/toplistTemplate.js" /* webpackChunkName: "component---src-templates-toplist-template-js" */),
  "component---src-templates-blog-template-js": () => import("/Users/fkaroot/dev/betterhealth.com/hello-world/src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-pages-index-js": () => import("/Users/fkaroot/dev/betterhealth.com/hello-world/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/Users/fkaroot/dev/betterhealth.com/hello-world/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/fkaroot/dev/betterhealth.com/hello-world/.cache/data.json")

